import * as React from "react"
import { Link } from "gatsby"
import { 
  AboutContainer,
  AboutStyle,
  Title } from "../components/About/AboutElements"
// styles
import Layout from "../layout"

const NotFoundPage = () => {
  return (
    <AboutStyle>
      <AboutContainer>
        <title>Not found</title>
        <Title >Page not found</Title>
        <p >
          Sorry{" "}
          <span role="img" aria-label="Pensive emoji">
            😔
          </span>{" "}
          we couldn’t find what you were looking for.
    
          <Link to="/">Go home</Link>.
        </p>
      </AboutContainer>
    </AboutStyle>
 
  )
}

NotFoundPage.Layout = Layout
export default NotFoundPage
